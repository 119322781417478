import Vue from 'vue'
import VueRouter from 'vue-router'
import VueResource from 'vue-resource'
import App from './App.vue'
import router from './config/routes'
import VueI18n from 'vue-i18n'
// import 'vuetify/dist/vuetify.min.css'
import Vuetify from 'vuetify'
import messages from './translations/messages';

Vue.use(VueI18n)
Vue.use(Vuetify)
Vue.use(VueRouter)
Vue.use(VueResource)

window.CURRENT_LOCALE = document.querySelector('body').getAttribute('data-lang');

const i18n = new VueI18n({
  locale: window.CURRENT_LOCALE,
  fallbackLocale: 'fr',
  messages
});

export default class Dashboard {
  constructor () {
    this.init();
  }
  init () {
    this.vueApp = new Vue({
      el: '#ody-dashboard',
      router,
      render: h => h(App),
      data: {
        user: null,
        token: ''
      },
      i18n
    });

    console.log(this.vueApp);
  }
}
