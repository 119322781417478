<template>
  <div class="container" id="dashboard-home">
    <div class="container__layout">
      <h2 class="title title--cap title--grey">{{ $t('dashboard.hello') }} {{ user.first_name }}</h2>
      <div v-on:click="logout()" class="btn btn--small btn--red" id="btn-logout">
        <span class="btn__label">{{ $t('dashboard.logout') }}</span>
      </div>

      <div class="text text--common">
        <h3>{{$t('dashboard.informations')}}</h3>
        <p>{{ user.email }}</p>
        <p>{{ user.first_name }} {{user.last_name}}</p>
        <p>{{ user.address }}</p>
        <p>{{ user.city }}, {{user.country}}</p>
      </div>

      <router-link to="/edit-account" class="btn btn--gold">
        <span class="btn__label">
          {{ $t('dashboard.edit_btn') }}
        </span>
      </router-link>
    </div>

    <div class="container__layout">
      <h3 class="title title--cap title--grey">{{ $t('dashboard.subscriptions') }}</h3>
      <p class="subscription_status" v-bind:class="{ subscription_valid: validSubscriptions.length > 0 }">{{ globalStatus }}</p>

      <div v-if="loading_subscriptions">{{ $t('dashboard.loading') }}</div>
      <div v-else-if="validSubscriptions.length === 0">
        <router-link to="/subscribe" class="btn btn--gold">
          <span class="btn__label">
            {{ $t('dashboard.subscribe_me') }}
          </span>
        </router-link>
      </div>

      <div v-for="subscription in subscriptions.slice().reverse()" class="subscription">
        <div class="text text--common text--white">
          <h3 class="subscription__title">
            <span v-if="subscription.periodicity === 'yearly'">
            {{ $t('dashboard.yearly_subscription_via', [subscription.platform_name]) }} :
            </span>
            <span v-else>
            {{ $t('dashboard.monthly_subscription_via', [subscription.platform_name]) }} :
            </span>

            <span class="subscription__status" v-bind:class="{ subscription_valid: isValid(subscription), subscription_invalid: !isValid(subscription) }">{{ parsedStatus(subscription) }}</span>
          </h3>
          <p>{{ $t('dashboard.created_at') }} {{ formatDate(subscription.created_at) }}</p>
          <p>{{ $t('dashboard.validate_to') }} {{ formatDate(subscription.expire_date) }}</p>
          <div v-if="shouldShowStripeAction(subscription)" v-on:click="handleStripeAction(subscription)" class="stripe_action_button btn btn--gold">
            <span class="btn__label">
              {{ $t('dashboard.complete_subscription') }}
            </span>
          </div>

          <div v-if="shouldShowCancelAction(subscription)" v-on:click="handleCancelAction(subscription)" class="stripe_cancel_button btn btn--small btn--red">
            <span class="btn__label">
              {{ $t('dashboard.cancel_subscription') }}
            </span>
          </div>
        </div>
      </div>
    </div>


    <div v-for="error in errors">
      <p>{{ error }}</p>
    </div>
  </div>

</template>

<script>

export default {
  data () {
    return {
      token: this.$root.$data.token,
      user: this.$root.$data.user || {},
      loading_subscriptions: false,
      subscriptions: [],
      errors: [],
      stripe: null
    }
  },
  methods: {
    configureStripe: function() {
      if (!this.stripe) {
        var vm = this;
        this.stripe = Stripe('pk_test_ZCGMHufWK4Wy5VjpydiBsww400MskOuv2H');
      }
    },
    shouldShowStripeAction: function(subscription) {
      console.log(subscription.stripe_invoice_url)
      return subscription.stripe_invoice_url || subscription.stripe_client_secret;
    },
    shouldShowCancelAction: function(subscription) {
      if (!subscription.id) return false;
      let status = subscription.stripe_status;
      return status === 'trialing' || status === 'succeeded' || status === 'requires_payment_method';
    },
    getMe: function () {
      this.$http.get(API_URL + '/me', {
          responseType: "json",
          headers: {
            Authorization: 'Basic ' + this.$root.$data.token
          }
        })
        .then((response) => {
          this.user = response.body.user;
          console.log(this.user);
        }, function(){
          this.logout();
          this.errors = [this.$i18n.t('dashboard.generic_error')];
        })
        .catch(error => {
          this.logout();
        })
    },
    getSubscriptions: function() {
      this.loading_subscriptions = true;
      this.$http.post(API_URL + '/validate-subscriptions', {}, {
          responseType: "json",
          headers: {
            Authorization: 'Basic ' + this.$root.$data.token
          }
        })
        .then((response) => {
          this.loading_subscriptions = false;
          this.subscriptions = response.body;
          this.errors = [];
        }, function(response){
          this.loading_subscriptions = false;
          this.errors = [response.body.message[this.$i18n.locale]];
        })
        .catch(error => {
          this.loading_subscriptions = false;
          this.errors = [error];
        })
    },
    handleCancelAction: function(subscription) {
      if (!this.shouldShowCancelAction(subscription)) {
        return;
      }
      this.errors = [];
      this.loading_subscriptions = true;
      this.$http.post(API_URL + '/subscriptions/' + subscription.id + '/cancel', {}, {
          responseType: "json",
          headers: {
            Authorization: 'Basic ' + this.$root.$data.token
          }
        })
        .then((response) => {
          this.loading_subscriptions = false;
          this.getSubscriptions();
        }, function(response){
          this.loading_subscriptions = false;
          this.errors = [response.body.message[this.$i18n.locale]];
        })
        .catch(error => {
          this.loading_subscriptions = false;
          this.errors = [error];
        })
    },
    handleStripeAction: function(subscription) {
      this.configureStripe();

      if (!this.shouldShowStripeAction(subscription)) {
        return;
      }

      if (subscription.stripe_invoice_url) {
        window.open(subscription.stripe_invoice_url, "_blank");
        return;
      }

      var vm = this;

      let client_secret = subscription.stripe_client_secret;
      this.stripe.confirmCardPayment(client_secret).then(function(result) {
        vm.getSubscriptions();
      });
    },
    logout: function() {
      localStorage.removeItem('user-token');
      window.refreshUserLogged();
      this.$router.push('/login');
    },
    hasExpired: function(subscription) {
      return new Date(subscription.expire_date) < new Date();
    },
    isValid: function(subscription, allowCanceled = false) {
      if (this.hasExpired(subscription)) return false;
      if (subscription.platform === 'stripe') {
        if (allowCanceled && subscription.stripe_status === 'canceled') {
          return true
        }
        return subscription.stripe_status === 'succeeded' || subscription.stripe_status === 'trialing';
      }
      return true;
    },
    formatDate: function(date) {
      if (typeof date === 'string') {
        date = new Date(date);
      }
      return date.toLocaleString(this.$i18n.locale, { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
    },
    parsedStatus: function(subscription) {
      const expiredStatus = this.hasExpired(subscription) ? this.$i18n.t('subscriptions.expired') : this.$i18n.t('subscriptions.ongoing');
      switch (subscription.stripe_status) {
        case null:
        case undefined:
        case 'succeeded':
          return expiredStatus;
        case 'trialing':
          return this.$i18n.t('subscriptions.trial') + ' - '  + expiredStatus;
        case 'requires_action':
          return this.$i18n.t('subscriptions.requires_action');
        case 'requires_payment_method':
          return this.$i18n.t('subscriptions.requires_payment_method');
        case 'canceled':
          return expiredStatus + ' (' + this.$i18n.t('subscriptions.canceled').toLowerCase() + ')';
        case 'processing':
          return this.$i18n.t('subscriptions.processing');
        default:
          return subscription.stripe_status;
      }
    }
  },
  computed: {
    validSubscriptions: function() {
      const vm = this;
      return this.subscriptions.filter(function (s) { return vm.isValid(s, false); });
    },
    globalStatus: function() {
      const vm = this;
      const valid = this.subscriptions.filter(function (s) { return vm.isValid(s, true); });
      if (valid.length === 0) {
        return this.$i18n.t('subscriptions.no_subscription');
      }
      const expirationDates = valid.map(function (s) { return new Date(s.expire_date); });
      const maxDate = new Date(Math.max.apply(null, expirationDates));
      return this.$i18n.t('subscriptions.subscribed_until') + this.formatDate(maxDate);
    }
  },
  mounted () {
    if (!localStorage.getItem('user-token')) {
      this.$router.push('/login');
      return;
    }

    this.$root.$data.token = localStorage.getItem('user-token');
    if (!this.$root.$data.user) {
      this.getMe();
    }
    if (!this.$root.$data.subscriptions) {
      this.getSubscriptions();
    }

    if (typeof Stripe !== 'function') {
      let script = document.createElement('script')
      script.onload = () => {
        this.stripe_script_loaded = true;
        this.configureStripe();
      }
      script.async = true
      script.src = 'https://js.stripe.com/v3/'
      document.head.appendChild(script)
    }
    else {
      this.configureStripe();
    }
  }
}
</script>

<style lang="sass">

</style>
