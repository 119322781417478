import Home from './pages/Home';
import About from './pages/About';
import Page from './pages/Page';

export default class App {
  constructor () {
    window.refreshUserLogged();
    $(window).on('load', () => {
      this.init();
      window.refreshUserLogged();
    });
  }
  init () {
    this.setupMenu();

    this.$page = $('main.page');

    if (this.$page.hasClass('page--home')) {
      this.page = new Home();
    } else if (this.$page.hasClass('page--about')) {
      this.page = new About();
    } else {
      this.page = new Page();
    }
  }
  setupMenu () {
    let $header = $('#header');
    let $burger = $header.find('.header__burger');

    $burger.on('click', ()=>{
      $header.toggleClass('is-opened');
      $burger.toggleClass('is-opened');
    });

  }
}
