<template>
  <v-form ref="form" lazy-validation>
    <v-container>

      <v-layout row wrap>

        <v-flex xs12 md6>
          <v-text-field
            v-model="first_name"
            :rules="requiredRules"
            :label="$t('dashboard.firstname')"
            required
          ></v-text-field>
        </v-flex>

        <v-flex xs12 md6>
          <v-text-field
            v-model="last_name"
            :rules="requiredRules"
            :label="$t('dashboard.lastname')"
            required
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            :label="$t('dashboard.email')"
            required
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-text-field
            v-model="address"
            :label="$t('dashboard.address')"
            :rules="requiredRules"
            required
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-text-field
            v-model="city"
            :label="$t('dashboard.city')"
            :rules="requiredRules"
            required
          ></v-text-field>
        </v-flex>

          <v-flex xs12>
          <v-text-field
            v-model="country"
            :label="$t('dashboard.country')"
            :rules="requiredRules"
            required
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-text-field
            v-model="vat_number"
            :label="$t('dashboard.vat_number')"
          ></v-text-field>
        </v-flex>

        <div class="btn btn--gold"
         @click="sendForm">
          <span class="btn__label">
            {{ $t('dashboard.validate') }}
          </span>
        </div>
      </v-layout>
    </v-container>
    <v-container>
      <v-layout row wrap>
        <div class="btn btn--blue"
        @click="resetPassword">
          <span class="btn__label" v-if="can_reset">
            {{ $t('dashboard.change_my_password') }}
          </span>
          <span v-else>
            {{ $t('dashboard.forgot_password_email_sent') }}
          </span>
        </div>
      </v-layout>
    </v-container>
    <v-container>
      <v-layout row wrap>
        <router-link to="/" class="btn btn--gold">
          <span class="btn__label">
            {{ $t('dashboard.back') }}
          </span>
        </router-link>
      </v-layout>
    </v-container>
  </v-form>


</template>

<script>

export default {
  data () {
    return {
      can_reset: true,
      valid: true,
      first_name: '',
      last_name: '',
      address: '',
      city: '',
      country: '',
      vat_number: '',
      email: '',
      errors: [],
      requiredRules : [
        v => !!v || this.$i18n.t('dashboard.required_field'),
      ],
      emailRules: [
        v => !!v || this.$i18n.t('dashboard.required_email'),
        v => (/^[^@\s]+@[^@\s]+$/).test(v) || this.$i18n.t('dashboard.invalid_email')
      ],
      show1: false,
      passwordRules: [
        v => !!v || this.$i18n.t('dashboard.password_too_short'),
        v => v.length >= 8 || this.$i18n.t('dashboard.password_too_short'),
        v => new RegExp("^(?=.*[0-9])(?=.*[\_!@#\$€£§%\^&\*\-])").test(v) || this.$i18n.t('dashboard.password_requires_digit_and_symbol'),
      ],
      passwordConfirmRules: [
        v => !!v || this.$i18n.t('dashboard.confirm_password'),
        v => v === this.password || this.$i18n.t('dashboard.passwords_dont_match')
      ]
    }
  },
  methods: {
    sendForm: function () {
      const user = this.$root.$data.user;
      const data = {
        first_name: this.first_name,
        last_name: this.last_name,
        address: this.address,
        city: this.city,
        country: this.country,
        vat_number: this.vat_number,
        email: this.email,
        token: this.token
      };

      this.errors = [];

      this.$http.post(API_URL + '/me', data, {
        responseType: "json",
        headers: {
            Authorization: 'Basic ' + this.$root.$data.token
          }
        })
        .then(function(response) {
          let user = response.body.user;
          this.$root.$data.user = user;

          this.$router.push('/');
        }, function(response) {
          this.errors = [response.body.message[this.$i18n.locale]];
        })
        .catch(error => {
          this.errors = [this.$i18n.t('dashboard.generic_error')];
        })
    },
    resetPassword () {
      if (!this.can_reset) {
        return;
      }

      this.errors = [];

      this.$http.post(API_URL + '/password/forgot', {email: this.email}, {
        responseType: "json",
        headers: {
            Authorization: 'Basic ' + this.$root.$data.token
          }
        })
        .then((response) => {
          // this.$router.push('/');
          this.can_reset = false;
        }, function(response) {
          this.errors = [response.body.message[this.$i18n.locale]];
        })
        .catch(error => {
          this.errors = [this.$i18n.t('dashboard.generic_error')];
        })
    },
    getMe () {
      this.$http.get(API_URL + '/me', {
          responseType: "json",
          headers: {
            Authorization: 'Basic ' + this.$root.$data.token
          }
        })
        .then((response) => {
          this.$root.$data.user = response.body.user;
          this.user = response.body.user;
          this.updateFields();
        }, () => {
          this.errors = [this.$i18n.t('dashboard.generic_error')];
        })
        .catch((error) => {
          this.errors = [this.$i18n.t('dashboard.generic_error')];
        })
    },
    updateFields () {
      this.first_name = this.user.first_name;
      this.last_name = this.user.last_name;
      this.address = this.user.address;
      this.city = this.user.city;
      this.country = this.user.country;
      this.vat_number = this.user.vat_number;
      this.email = this.user.email;
    },
  },
  mounted() {
    if (!localStorage.getItem('user-token')) {
      this.$router.push('/login');
      return;
    }

    this.$root.$data.token = localStorage.getItem('user-token');
    if (!this.$root.$data.user) {
      this.getMe();
    } else {
      this.updateFields();
    }
  }
}
</script>

<style lang="sass">

</style>
