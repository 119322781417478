import VueRouter from 'vue-router'
import ChangePassword from '../components/ChangePassword'
import CreateAccount from '../components/CreateAccount'
import PasswordSuccess from '../components/PasswordSuccess'
import AccountSuccess from '../components/AccountSuccess'
import Home from '../components/Home'
import Login from '../components/Login'
import EditAccount from '../components/EditAccount'
import NewAccount from '../components/NewAccount'
import Subscribe from '../components/Subscribe'

const router = new VueRouter({
  mode: 'hash',
  base: '/fr/dashboard/',
  routes: [
    { path: '/', component: Home },
    { path: '/login', component: Login },
    { path: '/new-account', component: NewAccount },
    { path: '/edit-account', component: EditAccount },
    { path: '/change-password', component: ChangePassword },
    { path: '/create-account', component: CreateAccount },
    { path: '/change-password-success', component: PasswordSuccess },
    { path: '/account-success', component: AccountSuccess },
    { path: '/subscribe', component: Subscribe }
  ]
})

export default router;
