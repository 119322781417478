<template>
  <v-app id="ody-dashboard">
    
    <v-content>
      <router-view class="view"></router-view>
    </v-content>
  </v-app>
</template>

<script>

export default {
  name: 'app'
}

window.API_URL = "https://dev.api.odysound.com"
window.ENV_MODE = "dev"

if (window.location.host === "localhost:8080") {
  window.API_URL = "http://localhost:3000"
} else if (window.location.host === "dev.app.odysound.com") {
  window.API_URL = "https://dev.api.odysound.com"
} else if (window.location.host === "app.odysound.com") {
  window.API_URL = "https://api.odysound.com"
  window.ENV_MODE = "live"
}


</script>

<style lang="scss">

</style>
