<template>
  <div>

    <div class="text text--common" v-if="new_subscription">
      <h3>{{ $t('new_subscriber_page.title') }}</h3>
      <p v-html="$t('new_subscriber_page.description')"></p>
    </div>

    <div class="text text--common" v-if="!new_subscription">
      <h3>{{ $t('subscriber_page.title') }}</h3>
      <p v-html="$t('subscriber_page.description')"></p>
    </div>

    <div id="period" class="subscription-periods">
      <div v-for="(price, index) in available_subscriptions" :key="index" class="subscription-period" v-bind:selected="selected_subscription_price_id === price.stripe_id">
        <input type="radio" :id="price.stripe_id" :value='price.stripe_id' v-model="selected_subscription_price_id">
        <label :for="price.stripe_id">
          {{ price.title }}
          <p v-html="price.description"></p>
          <p v-if="price.footnote.length > 0" class="footnote">{{ price.footnote }}</p>
        </label>
      </div>
    </div>

    <div class="text text--common">
      <div id="stripe" class="text">
        <!-- Use the CSS tab above to style your Element's container. -->
        <div id="card-element" class="MyCardElement">
          <!-- Elements will create input elements here -->
        </div>

        <!-- We'll put the error messages in this element -->
        <div id="card-errors" role="alert"></div>

        <button v-show="!subscribing" id="submit" class="btn btn--gold">
          <span class="btn__label">
            {{ $t('dashboard.subscribe') }}
          </span>
        </button>
        <div v-if="subscribing && stripe_payment_method_id == null">
          {{ $t('dashboard.saving_card') }}
        </div>
        <div v-if="subscribing && stripe_payment_method_id != null">
          {{ $t('dashboard.creating_subscription') }}
        </div>
      </div>
    </div>

    <div v-for="error in errors">
      <p>{{ error }}</p>
    </div>
  </div>
</template>


<script>

import STRIPECONFIG from '../config/stripe';

const PK_KEY = window.ENV_MODE === "live" ? STRIPECONFIG.public_live_key : STRIPECONFIG.public_test_key;
const PRICE_MONTHLY_ID = window.ENV_MODE === "live" ? STRIPECONFIG.stripe_monthly_price_id_live : STRIPECONFIG.stripe_monthly_price_id_test
const PRICE_YEARLY_ID = window.ENV_MODE === "live" ? STRIPECONFIG.stripe_yearly_price_id_live : STRIPECONFIG.stripe_yearly_price_id_test

export default {
  data() {
    return {
      token: this.$root.$data.token,
      stripe_script_loaded: this.$root.$data.stripe_script_loaded,
      stripe_payment_method_id: this.$root.$data.stripe_payment_method_id,
      errors: [],
      subscribing: false,
      new_subscription: false,
      selected_subscription_price_id: PRICE_YEARLY_ID,
      subscriptions: [],
      available_subscriptions: [
        {
          stripe_id: PRICE_YEARLY_ID,
          title: this.$t('subscriptions.yearly.title'),
          description: this.$t('subscriptions.yearly.description'),
          footnote: this.$t('subscriptions.yearly.footnote'),
        },
        {
          stripe_id: PRICE_MONTHLY_ID,
          title: this.$i18n.t('subscriptions.monthly.title'),
          description: this.$i18n.t('subscriptions.monthly.description'),
          footnote: this.$t('subscriptions.monthly.footnote'),
        }
      ]
    }
  },
  methods: {
    configureStripe: function() {
      let vm = this;
      let stripe = Stripe(PK_KEY);
      let elements = stripe.elements();

      // Set up Stripe.js and Elements to use in checkout form
      // https://stripe.com/docs/billing/subscriptions/set-up-subscription#test-integration
      let style = {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "18px",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      };

      let card = elements.create("card", { style: style });
      card.mount("#card-element");
      card.addEventListener('change', ({error}) => {
        this.updateStripeError(error)
      });

      document.getElementById('submit').addEventListener("click", function(e) {
        vm.subscribing = true;

        stripe.createPaymentMethod('card', card, {}).then(function(result) {
          vm.errors = [];
          vm.updateStripeError(result.error)

          if (result.error) {
            vm.subscribing = false;
          } else {
            vm.stripe_payment_method_id = result.paymentMethod.id;
            vm.createSubscription(vm.stripe_payment_method_id);
          }
        });
      });
    },

    updateStripeError: function(error) {
      const displayError = document.getElementById('card-errors');
      if (error) {
        const key = 'stripe.' + error.code
        let message = this.$i18n.t(key)
        if (message === key) {
          message = error.message;
        }
        displayError.textContent = message;
      } else {
        displayError.textContent = '';
      }
    },

    getCurrentSubscription: function () {
      this.$http.post(API_URL + '/validate-subscriptions', {}, {
          responseType: "json",
          headers: {
            Authorization: 'Basic ' + this.$root.$data.token
          }
        })
        .then((response) => {
          this.subscriptions = response.body;
          this.new_subscription = !this.subscriptions.length > 0;
        }, (response) => {
          this.subscribing = false;
          this.addError(response.body.message[this.$i18n.locale]);
        })
        .catch(error => {
          this.subscribing = false;
          this.addError(error);
        })
    },
    createSubscription: function(payment_method) {
      let params = {};
      params.stripe_plan_id = this.$data.selected_subscription_price_id;
      params.stripe_payment_method_id = payment_method;

      this.errors = [];

      this.loading_subscriptions = true;
      this.$http.post(API_URL + '/validate-subscriptions', params, {
          responseType: "json",
          headers: {
            Authorization: 'Basic ' + this.$root.$data.token
          }
        })
        .then((response) => {
          this.subscribing = false;
          if (response.status === 200) {
            // TODO: récupérer le dernier abonnement créé (en triant sur response.body[].created_at)
            // et s'il y a une action 3DS il faut la déclencher immédiatement, avant d'aller sur
            // la page principale du dashboard
            this.$router.push('/');
          }
        }, (response) => {
          this.subscribing = false;
          this.addError(response.body.message[this.$i18n.locale]);
        })
        .catch(error => {
          this.subscribing = false;
          this.addError(error);
        })
    },
    addError (error) {
      if (typeof error === "string") {
        this.errors = [error];
      } else if(error.message && typeof error.message === 'string') {
        this.errors = [error.message];
      } else {
        this.errors = [this.$i18n.t('dashboard.generic_error')]
      }
    }
  },
  computed: {
  },
  mounted () {
    if (!localStorage.getItem('user-token')) {
      this.$router.push('/login');
      return;
    }
    this.$root.$data.token = localStorage.getItem('user-token');

    if (typeof Stripe !== 'function') {
      let script = document.createElement('script');
      script.onload = () => {
        this.stripe_script_loaded = true;
        this.configureStripe();
      }
      script.async = true;
      script.src = 'https://js.stripe.com/v3/';
      document.head.appendChild(script);
    }
    else {
      this.configureStripe();
    }

    this.getCurrentSubscription();
  }
}
</script>

<style lang="sass">

</style>
