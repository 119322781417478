export default {
  public_test_key: 'pk_test_ZXnmENwMa6CSw5y3DnjblWbN00l76qahnX',
  public_live_key: 'pk_live_kx7Sfyei4f6nVZ0bkhjzeRrd00EQdXwoPS',

  stripe_monthly_price_id_test: 'price_1Jts86C70ls9VO48wLPboeD9',
  stripe_yearly_price_id_test: 'price_1Jts9LC70ls9VO48anFU57i3',

  stripe_monthly_price_id_live: 'price_1JtsAyC70ls9VO48DntoTwkU',
  stripe_yearly_price_id_live: 'price_1JtsBgC70ls9VO48RAIfzqeh'
};
