<template>
  <v-form ref="form" v-model="valid" lazy-validation @submit="sendForm">
    <div class="text text--common">
      <h3>{{ $t('new_account_page.title') }}</h3>
      <p v-html="$t('new_account_page.description')"></p>
    </div>
    <v-container>
      <v-layout>
        <v-text-field
          v-model="email"
          :rules="emailRules"
          :label="$t('dashboard.email')"
          required
        ></v-text-field>

        <v-btn
            class="btn btn--gold"
            type="submit"
            :disabled="!valid || sended || loading"
          >
          <span class="btn__label">
            {{$t('dashboard.send_btn')}}
          </span>
        </v-btn>
      </v-layout>

      <ul id="example-1">
        <li v-for="message in messages">
          {{ message }}
        </li>
      </ul>
    </v-container>
  </v-form>
</template>

<script>

export default {
  data () {
    return {
      valid: false,
      sended: false,
      loading: false,
      email: '',
      messages: [],
      requiredRules : [
        v => !!v || this.$i18n.t('dashboard.required_field'),
      ],
      emailRules: [
        v => !!v || this.$i18n.t('dashboard.required_email'),
        v => (/^[^@\s]+@[^@\s]+$/).test(v) || this.$i18n.t('dashboard.invalid_email')
      ]
    }
  },
  methods: {
    sendForm: function (event) {
      event.preventDefault();
      const data = {
        email: this.email,
        lang: this.$i18n.locale
      };
      this.loading = true;
      this.messages = []

      this.$http.post(API_URL + "/create-account", data, {responseType: "json"})
        .then((response) => {
          this.messages.push(this.$i18n.t('dashboard.invitation_email_sent'));
          this.sended = true;
          this.loading = false;
        }, (response) => {
          this.messages.push(response.body.message[this.$i18n.locale]);
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.messages.push(this.$i18n.t('dashboard.generic_error'));
        })
    }
  },
  mounted() {

  }
}
</script>
