<template>
  <v-container fluid fill-height>
      <v-layout justify-center align-center>
        <v-flex xs12 sm8 md4>
          <v-card>
            <v-card-title primary-title>
              <div>
                <h3 class="headline mb-0">{{ $t('dashboard.account_created') }}</h3>
              </div>
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
  </v-container>

</template>

<script>
export default {
  data () {
    return {

    }
  }
}
</script>

<style lang="sass">

</style>
