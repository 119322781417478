<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-container grid-list-md>
      <v-layout row wrap>

        <v-flex xs12>
          <v-text-field
            v-model="password"
            :rules="passwordRules"
            v-bind:label="$t('dashboard.password')"
            :append-icon="show1 ? 'visibility' : 'visibility_off'"
            :type="show1 ? 'text' : 'password'"
            counter
            @click:append="show1 = !show1"
            required
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-text-field
            v-model="password_confirm"
            :rules="passwordConfirmRules"
            :append-icon="show1 ? 'visibility' : 'visibility_off'"
            :type="show1 ? 'text' : 'password'"
            v-bind:label="$t('dashboard.confirm_password')"
            required
            @click:append="show1 = !show1"
          ></v-text-field>
        </v-flex>
      </v-layout>

      <v-layout>
        <v-btn
            :disabled="!valid"
            @click="checkForm"
            class="btn btn-gold"
          >
          <span class="btn__label">
            {{$t('dashboard.send_btn')}}
          </span>
        </v-btn>
      </v-layout>

      <v-layout>
        <div v-for="error in errors" v-bind:key="error">
          <p>{{ error }}</p>
        </div>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
const apiUrl = API_URL + "/password/reset";

const validateParams = {
  email: "m",
  token: "t"
}

export default {
  data () {
    return {
      valid: true,
      password: '',
      password_confirm: '',
      show1: false,
      passwordRules: [
        v => !!v || this.$i18n.t('dashboard.password_too_short'),
        v => v.length >= 8 || this.$i18n.t('dashboard.password_too_short'),
        v => new RegExp("^(?=.*[0-9])(?=.*[\_!@#\$€£§%\^&\*\-])").test(v) || this.$i18n.t('dashboard.password_requires_digit_and_symbol'),
      ],
      passwordConfirmRules: [
        v => !!v || this.$i18n.t('dashboard.confirm_password'),
        v => v === this.password || this.$i18n.t('dashboard.passwords_dont_match')
      ],
      email: null,
      token: null,
      errors: [],
   }
  },
  methods: {
    checkForm: function (e) {
      if (this.email && this.password && this.password_confirm === this.password) {
        return this.sendForm();
      }
    },
    sendForm: function () {
      const data = {email: this.email, password: this.password, token: this.token};

      this.errors = [];
      this.$http.post(apiUrl, data, {
          responseType: "json",
        })
        .then(function() {
          this.$router.push('/change-password-success');
        }, function(response) {
          this.errors = [response.body.message[this.$i18n.locale]];
        })
        .catch(error => {
          this.errors = [this.$i18n.t('dashboard.generic_error')];
        })
    }
  },
  mounted() {
    if (!this.$route.query[validateParams.email] || !this.$route.query[validateParams.token] ) {
      return this.$router.push('/')
    }

    this.token = this.$route.query[validateParams.token]
    this.email = this.$route.query[validateParams.email]
  }
}
</script>
