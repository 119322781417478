import Hammer from 'hammerjs';
import Page from './Page';

export default class About extends Page {
  init () {
    console.log('init about ')
    this.setupSlideshow();
    this.setupPartners();
  }
  setupSlideshow () {
    let slidesLength = $('.sliders__slides img').length;

    let $carousel = $('.sliders__slides').owlCarousel({
      center: true,
      items: 1,
      loop: true
    });

    $carousel.parents('.sliders').find('.arrow').on('click', function () {
      if ($(this).hasClass('arrow--left')) {
        $carousel.trigger('prev.owl.carousel');
      } else {
        $carousel.trigger('next.owl.carousel');
      }
    });

    let $dragbar = $carousel.parents('.sliders').find('.sliders__dragbar');
    let $cursor = $dragbar.find('.sliders__dragbar__cursor');
    let hammer = new Hammer($dragbar[0]);
    let curSlideIndex = 0;
    let dragging = false;

    hammer.on('panleft panright', (event) => {
      dragging = true;
      $cursor.addClass('is-grabbed');

      let x = (event.center.x - $dragbar.offset().left) / $dragbar.width();
      x = Math.max(0, Math.min(x, 1));
      let newSlideIndex = Math.round((slidesLength - 1) * x);

      $cursor.css({ 
        left: x * 100 + '%'
      });

      if (newSlideIndex !== curSlideIndex) {
        curSlideIndex = newSlideIndex;
        $carousel.trigger('to.owl.carousel', [curSlideIndex]);
      }
    });

    hammer.on('panend', () => {
      dragging = false;
      $cursor.removeClass('is-grabbed');
      $cursor.stop().animate({ 
        left: (curSlideIndex / (slidesLength - 1)) * 100 + '%'
      }, 100);
    });

    $carousel.on('changed.owl.carousel', (event) => {
      if (dragging) {
        return false;
      }

      let index = this.fixOwlCurrentIdx(event);

      $cursor.stop().animate({ 
        left: (index / (slidesLength - 1)) * 100 + '%'
      }, 300);
    });
  }
}
