import 'owl.carousel';
import Dashboard from '../dashboard';

class Page {
  constructor () {
    this.element = $('main');

    if (this.element.hasClass('page--dashboard')) {
      this.dashboard = new Dashboard();
    }

    this.init();
  }
  init () {
    this.setupSlideshows();
    this.setupPartners();
  }
  setupSlideshows () {
    let $carousel = $('.sliders__slides').owlCarousel({
      center: true,
      items: 2,
      loop: false,
      nav: true,
      margin: 30,
      responsive: {
        600: {
          items: 4
        }
      }
    });

    $carousel.parents('.sliders').find('.arrow').on('click', function () {
      if ($(this).hasClass('arrow--left')) {
        $carousel.find('.owl-prev').click();
      } else {
        $carousel.find('.owl-next').click();
      }
    });
  }
  setupPartners () {
    let $slideshow = $('.slideshow-partners');
    let $pageIndex = $slideshow.find('.pagination__current');
    let $pageTotal = $slideshow.find('.pagination__total');
    let $carousel = $slideshow.find('.slideshow-partners__slides').owlCarousel({
      items: 4,
      slideBy: 'page',
      loop: true,
      nav: true,
      margin: 0,
      responsive: {
        0: {
          items: 1
        },
        460: {
          items: 2
        },
        700: {
          items: 3
        },
        1024: {
          items: 4
        }
      }
    });

    $carousel.parents('.slideshow-partners').find('.arrow').on('click', function () {
      if ($(this).hasClass('arrow--left')) {
        $carousel.find('.owl-prev').click();
      } else {
        $carousel.find('.owl-next').click();
      }
    });

    $carousel.on('changed.owl.carousel', (event) => {
      $pageIndex.text('0' + (event.page.index + 1));
      $pageTotal.text('0' + (event.page.count));
    });

    $carousel.on('refreshed.owl.carousel', (event) => {
      $pageIndex.text('0' + (event.page.index + 1));
      $pageTotal.text('0' + (event.page.count));
    });
    $carousel.trigger('refresh.owl.carousel');

  }
  fixOwlCurrentIdx (event) {
    let current = (event.item.index + 1) - event.relatedTarget._clones.length / 2;
    let itemsCount = event.item.count;

    if (current > itemsCount || current === 0) {
      current = itemsCount - (current % itemsCount);
    }
    return current - 1;
  }
}

export default Page
