import Page from './Page';
import Hammer from 'hammerjs';

export default class Home extends Page {
  init () {
    console.log('init hm ')
    this.setupSlideshow();
    this.setupTopSlideshow();
  }
  setupSlideshow () {
    let slidesLength = $('.sliders__slides img').length;

    let $carousel = $('.sliders__slides').owlCarousel({
      center: true,
      // center: true,
      items: 2,
      loop: true,
      nav: true,
      margin: 30,
      responsive: {
        0: {
          items: 1
        },
        460: {
          items: 2
        },
        768: {
          items: 3
        },
        1024: {
          items: 4
        }
      }
    });
    let $dragbar = $carousel.parents('.sliders').find('.sliders__dragbar');
    let $cursor = $dragbar.find('.sliders__dragbar__cursor');
    let hammer = new Hammer($dragbar[0]);
    let curSlideIndex = 0;
    let dragging = false;

    let $backgroundSlides = $('.catalog__sliders-background .slide');

    $carousel.parents('.sliders').find('.arrow').on('click', function () {
      if ($(this).hasClass('arrow--left')) {
        $carousel.trigger('prev.owl.carousel');
      } else {
        $carousel.trigger('next.owl.carousel');
      }
    });

    $carousel.on('changed.owl.carousel', (event) => {
      let index = this.fixOwlCurrentIdx(event);
      $backgroundSlides.removeClass('is-active').eq(index).addClass('is-active');
    });

    hammer.on('panleft panright', (event) => {
      dragging = true;
      $cursor.addClass('is-grabbed');

      let x = (event.center.x - $dragbar.offset().left) / $dragbar.width();
      x = Math.max(0, Math.min(x, 1));
      let newSlideIndex = Math.round((slidesLength - 1) * x);

      $cursor.css({ 
        left: x * 100 + '%'
      });

      if (newSlideIndex !== curSlideIndex) {
        curSlideIndex = newSlideIndex;
        $carousel.trigger('to.owl.carousel', [curSlideIndex]);
      }
    });

    hammer.on('panend', () => {
      dragging = false;
      $cursor.removeClass('is-grabbed');
      $cursor.stop().animate({ 
        left: (curSlideIndex / (slidesLength - 1)) * 100 + '%'
      }, 100);
    });

    $carousel.on('changed.owl.carousel', (event) => {
      if (dragging) {
        return false;
      }

      let index = this.fixOwlCurrentIdx(event);

      $cursor.stop().animate({ 
        left: (index / (slidesLength - 1)) * 100 + '%'
      }, 300);
    });
  }
  setupTopSlideshow () {
    let $carousel = $('.slideshow-videos__slides').owlCarousel({
      center: true,
      loop: true,
      nav: true,
      items: 1,
      itemsDesktop: false,
      itemsDesktopSmall: false,
      itemsTablet: false,
      itemsMobile: false,
      animateOut: 'fadeOut'
    });

    $carousel.parents('.slideshow-videos').find('.arrow').on('click', function () {
      if ($(this).hasClass('arrow--left')) {
        $carousel.trigger('prev.owl.carousel');
      } else {
        $carousel.trigger('next.owl.carousel');
      }
    });
  }
}
