import App from './App';

window.refreshUserLogged = function () {
  window.isUserLogged = window.localStorage.getItem('user-token') !== null;
  if (window.isUserLogged) {
    $('body').addClass('user-logged');
  } else {
    $('body').removeClass('user-logged');
  }
};

const app = new App();
