<template>
  <v-container fluid fill-height>
      <v-layout justify-center align-center>
        <div>
          <h3 class="headline mb-0">{{ $t('dashboard.password_updated') }}</h3>
        </div>
      </v-layout>
  </v-container>

</template>

<script>
export default {
  data () {
    return {
      test_url: process.env.LOCAL_API_URL
    }
  }
}
</script>

<style lang="sass">

</style>
