export default {
  en: {
    dashboard: {
      hello: 'Hello',
      send_btn: 'Send',
      login: 'Login',
      logout: 'Logout',
      password: 'Password',
      forgot_password: 'Password forgotten',
      forgot_password_email_sent: 'We\'ve sent you an email',
      firstname: 'First name',
      lastname: 'Last name',
      address: 'Address',
      city: 'City',
      country: 'Country',
      vat_number: 'VAT number (if applicable)',
      email: 'Email',
      hint_password_length: 'At least 8 characters',
      confirm_password: 'Password confirmation',
      informations: 'Your information',
      edit_btn: 'Edit my information',
      subscriptions: 'Subscriptions',
      loading: 'Loading',
      subscribe_me: 'Subscribe',
      monthly_subscription_via: 'Monthly subscription via {0}',
      yearly_subscription_via: 'Annual subscription via {0}',
      created_at: 'Created on',
      validate_to: 'Valid until',
      complete_subscription: 'Complete subscription',
      cancel_subscription: 'Cancel subscription',
      subscribe: 'Subscribe',
      saving_card: 'Saving your card...',
      creating_subscription: 'Creation the subscription...',
      account_created: 'Thank you, your account has been created',
      invitation_email_sent: 'Thank you, an invitation email has been sent',
      required_field: 'This field is required',
      required_email: 'Email is required',
      invalid_email: 'Email must be valid',
      password_too_short: 'Password is too short (at least 8 characters)',
      password_requires_digit_and_symbol: 'Password must contain at least 1 digit and 1 symbol: ! @ $ % & # - _ € £ §',
      passwords_dont_match: 'Passwords don\'t match',
      generic_error: 'An error occurred, please try again later',
      password_updated: 'Your password has been updated.',
      validate: 'Confirm',
      change_my_password: 'Modify my password',
      back: 'Back'
    },
    create_account_page: {
      title: 'Registration',
      description: "You're almost there!<br>Complete your registration to start your free 7 days trial.<br>With this account you will enjoy the Odysound App on all existing and future platforms."
    },
    new_account_page: {
      title: 'Create your Odysound account',
      description: 'Please enter your email to receive your invite and create your account.<br>Click the link in the email to start your registration and enjoy your free 7 days trail.'
    },
    new_subscriber_page: {
      title: 'Last step before enjoying your free trial!',
      description: 'Enter a payment method and enjoy illimited acess to immersive universes created in a cinema studio.<br>You can manage and cancel your subscriptions in your account settings on the Odysound website.<br>After the free trial week, the subscription will automatically start unless you can it at least 24 hours before the end of your trial.'
    },
    subscriber_page: {
      title: 'Renewing',
      description: 'Please select a new payment method for your Odysound account.<br>Subscribe and enjoy illimited acess to immersive universes created in a cinema studio on all existing and future platforms!<br><br>You can manage and cancel your subscriptions in your account settings on the Odysound website.'
    },
    subscriptions: {
      expired: 'Expired',
      ongoing: 'Ongoing',
      requires_action: 'Requires validation',
      requires_payment_method: 'Payment denied',
      canceled: 'Canceled',
      trial: 'Free trial',
      processing: 'Pending validation',
      no_subscription: 'No ongoing subscription',
      subscribed_until: 'Currently subscribed until ',
      monthly: {
        title: 'Monthly',
        description: '8,99€<br/>First 7 days free',
        footnote: ''
      },
      yearly: {
        title: 'Annual',
        description: '57,99€ (4,83€ / month)<br/>First 7 days free',
        footnote: 'Best value'
      }
    },
    stripe: {
      api_connection_error: "Cannot connect to the payment provider's server. Please try again in a little bit.",
      card_declined: 'Your card has been declined.',
      expired_card: 'Your card has expired.',
      incomplete_card: 'Please fill in your card details.',
      incomplete_cvc: "Your card's security code is incomplete.",
      incomplete_expiry: "Your card's expiration date is incomplete.",
      incomplete_number: 'Your card number is incomplete.',
      incomplete_zip: 'Your postal code is incomplete.',
      incorrect_address: 'The card’s address is incorrect.',
      incorrect_cvc: "Your card's security code is incorrect.",
      incorrect_number: 'Your card number is incorrect.',
      incorrect_zip: 'The card’s postal code is incorrect.',
      invalid_card_holder: "Please enter the card holder's name.",
      invalid_card_type: 'The card provided as an external account is not a debit card.',
      invalid_cvc: "Your card's security code is invalid.",
      invalid_expiry_month: "Your card's expiration date is invalid.",
      invalid_expiry_month_past: "Your card's expiration date is in the past.",
      invalid_expiry_year: "Your card's expiration year is invalid.",
      invalid_expiry_year_past: "Your card's expiration year is in the past.",
      invalid_number: 'Your card number is invalid.',
      postal_code_invalid: 'The postal code provided was incorrect.',
      processing_error: 'An error occurred while processing your card. Please try again in a little bit.',
      processing_error_intransient: 'An error occurred while processing your card.'
    }
  },
  fr: {
    dashboard: {
      hello: 'Bonjour',
      send_btn: 'Envoyer',
      login: 'Se connecter',
      logout: 'Déconnexion',
      password: 'Mot de passe',
      forgot_password: 'Mot de passe oublié',
      forgot_password_email_sent: 'Un mail vous a été envoyé',
      firstname: 'Prénom',
      lastname: 'Nom',
      address: 'Adresse',
      city: 'Ville',
      country: 'Pays',
      vat_number: 'Numéro de TVA (si applicable)',
      email: 'E-mail',
      hint_password_length: 'Au moins 8 caractères',
      confirm_password: 'Confirmer le mot de passe',
      informations: 'Vos informations',
      edit_btn: 'Éditer mes informations',
      subscriptions: 'Abonnements',
      loading: 'Chargement',
      subscribe_me: 'M\'abonner',
      monthly_subscription_via: 'Abonnement mensuel via {0}',
      yearly_subscription_via: 'Abonnement annuel via {0}',
      created_at: 'Créé le',
      validate_to: 'Valide jusqu\'au',
      complete_subscription: 'Compléter l\'abonnement',
      cancel_subscription: 'Annuler l\'abonnement',
      subscribe: 'Souscrire',
      saving_card: 'Enregistrement de votre carte...',
      creating_subscription: 'Création de l\'abonnement...',
      account_created: 'Merci, votre compte a été créé',
      invitation_email_sent: 'Merci, un e-mail d\'invitation va vous être envoyé',
      required_field: 'Ce champ est obligatoire',
      required_email: 'E-mail obligatoire',
      invalid_email: 'E-mail doit être valide',
      password_too_short: 'Le mot de passe est trop court (minimum 8 caractères)',
      password_requires_digit_and_symbol: 'Le mot de passe doit contenir au moins 1 chiffre et 1 caractère spécial: ! @ $ % & # - _ € £ §',
      passwords_dont_match: 'La confirmation doit être similaire au mot de passe',
      generic_error: 'Une erreur est survenue, merci de réessayer ultérieurement',
      password_updated: 'Votre mot de passe a été modifié.',
      validate: 'Valider',
      change_my_password: 'Changer mon mot de passe',
      back: 'Retour'
    },
    create_account_page: {
      title: 'Inscription',
      description: 'Vous y êtes presque !<br>Complétez votre inscription afin de commencer votre période d’essai gratuite de 7 jours.<br>Avec ce compte, vous profiterez de l’App Odysound sur les plateformes existantes et à venir.'
    },
    new_account_page: {
      title: 'Créer votre compte Odysound',
      description: 'Veuillez rentrer votre e-mail afin de recevoir votre invitation à créer un compte.<br>Cliquez sur le lien dans l’email pour commencer votre inscription et profitez de votre essai gratuit de 7 jours.'
    },
    new_subscriber_page: {
      title: 'Dernière étape avant de profiter de votre essai gratuit !',
      description: 'Rentrez un moyen de paiement et profitez en illimité d’univers sonores immersifs créés en studio de cinéma.<br>Vous pouvez gérer et annuler vos abonnements dans les paramètres de comptes sur le site Odysound.<br>Après la semaine d’essai gratuit, l’abonnement est automatiquement enclenché si celui-ci n’est pas annulé au moins 24h avant la fin de la période d’essai.'
    },
    subscriber_page: {
      title: 'Renouvellement',
      description: 'Veuillez sélectionner un nouveau moyen de payement pour votre compte Odysound. <br>Abonnez-vous et profitez en illimité d’univers sonores immersifs créés en studios de cinéma sur toutes les plateformes existantes et à venir !<br><br>Vous pouvez gérer et annuler vos abonnements dans les paramètres de comptes sur le site Odysound.'
    },
    subscriptions: {
      expired: 'Expirée',
      ongoing: 'En cours',
      requires_action: 'Validation requise',
      requires_payment_method: 'Paiement rejeté',
      canceled: 'Annulé',
      trial: 'Essai gratuit',
      processing: 'En cours de validation',
      no_subscription: "Pas d'abonnement en cours",
      subscribed_until: 'Actuellement abonné jusqu\'au ',
      monthly: {
        title: 'Mensuel',
        description: '8,99€<br/>Essayez 7 jours gratuitement',
        footnote: ''
      },
      yearly: {
        title: 'Annuel',
        description: '57,99€ (4,83€ / mois)<br/>Essayez 7 jours gratuitement',
        footnote: 'La meilleure offre'
      }
    },
    stripe: {
      api_connection_error: 'Impossible de se connecter au fournisseur de paiement, merci de réessayer ultérieurement.',
      card_declined: 'Votre carte a été refusé.',
      expired_card: 'Votre carte a expiré.',
      incomplete_card: 'Merci de remplir les informations de votre carte.',
      incomplete_cvc: 'Le code de sécurité de votre carte est incomplet.',
      incomplete_expiry: "Le date d'expiration de votre carte est incomplète.",
      incomplete_number: 'Le numéro de votre carte est incomplet.',
      incomplete_zip: 'Le code postal est incomplet',
      incorrect_address: 'L\'adresse de votre carte est incomplète.',
      incorrect_cvc: 'Le code de sécurité de votre carte est érroné.',
      incorrect_number: 'Le numéro de votre carte est érroné.',
      incorrect_zip: 'Le code postal de votre carte est érroné.',
      invalid_card_holder: 'Merci de rentrer le nom associé à votre carte.',
      invalid_card_type: 'La carte fournie n\'est pas une carte de débit.',
      invalid_cvc: 'Le code se sécurité de votre carte n\'est pas valide.',
      invalid_expiry_month: 'Le mois d\'expiration de votre carte n\'est pas valide.',
      invalid_expiry_month_past: 'Le mois d\'expiration de votre carte est passé.',
      invalid_expiry_year: 'L\'année d\'expiration de votre carte n\'est pas valide.',
      invalid_expiry_year_past: 'L\'année d\'expiration de votre carte est passée.',
      invalid_number: 'Le numéro de votre carte n\'est pas valide.',
      postal_code_invalid: 'Le code postal est érroné.',
      processing_error: 'Une erreur est survenue lors de la vérification de votre carte, merci de réessayer ultérieurement.',
      processing_error_intransient: 'Une erreur est survenue lors de la vérification de votre carte.'
    }
  }
};
