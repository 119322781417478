<template>
  <v-form ref="form" v-model="valid" lazy-validation>

    <v-container>
      <v-text-field
        v-model="email"
        v-bind:label="$t('dashboard.email')"
        required
      ></v-text-field>

      <v-text-field
        v-model="password"
        v-bind:label="$t('dashboard.password')"
        type="password"
        required
      ></v-text-field>
    </v-container>

    <v-container>
      <v-layout>
        <div class="btn btn--gold"
          @click="checkForm">
          <span class="btn__label">
            {{$t('dashboard.login')}}
          </span>
        </div>
      </v-layout>

      <v-layout>
        <div v-for="error in login_errors" v-bind:key="error">
          <p>{{ error }}</p>
        </div>
      </v-layout>
    </v-container>

    <v-container>
      <v-layout>
        <div class="btn btn--blue"
        @click="resetPassword">
          <span class="btn__label" v-if="can_reset">
            {{$t('dashboard.forgot_password')}}
          </span>
          <span v-else>
            {{$t('dashboard.forgot_password_email_sent')}}
          </span>
        </div>
      </v-layout>

      <v-layout>
        <div v-for="error in reset_errors" v-bind:key="error">
          <p>{{ error }}</p>
        </div>
      </v-layout>
    </v-container>
  </v-form>

</template>

<script>

export default {
  data () {
    return {
      can_reset: true,
      valid: true,
      password: '',
      email: '',
      login_errors: [],
      reset_errors: [],
    }
  },
  methods: {
    checkForm: function (e) {
      if (this.email && this.password) {
        return this.sendForm();
      }
    },
    resetPassword () {
      if (!this.can_reset) {
        return;
      }

      this.login_errors = [];
      this.reset_errors = [];

      this.$http.post(API_URL + '/password/forgot', {email: this.email, lang: this.$i18n.locale}, {
        responseType: "json",
        headers: {
            Authorization: 'Basic ' + this.$root.$data.token
          }
        })
        .then((response) => {
          this.can_reset = false;
          // this.$router.push('/');
        }, (response) => {
          this.reset_errors = [response.body.message[this.$i18n.locale]];
        })
        .catch(error => {
          this.errors = [this.$i18n.t('dashboard.generic_error')];
        })
    },
    sendForm: function () {
      const data = {email: this.email, password: this.password};
      this.login_errors = [];
      this.reset_errors = [];
      this.can_reset = true;

      this.$http.post(API_URL + "/auth/login", data, {responseType: "json"})
        .then((response) => {
          let user = response.body.user;
          // this.$root.$data.user = user;
          this.$root.$data.token = response.body.auth_token;
          localStorage.setItem('user-token', response.body.auth_token);
          window.refreshUserLogged();
          this.$router.push('/');
        }, (response) => {
          this.login_errors = [response.body.message[this.$i18n.locale]];
        })
        .catch(error => {
          this.errors = [this.$i18n.t('dashboard.generic_error')];
        })
    }
  },
  mounted() {
  }
}
</script>

<style lang="sass">
</style>
