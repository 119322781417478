<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <div class="text text--common">
      <h3>{{ $t('create_account_page.title') }}</h3>
      <p v-html="$t('create_account_page.description')"></p>
    </div>

    <v-container>
      <v-layout row wrap>

        <v-flex xs12 md6>
          <v-text-field
            v-model="firstname"
            :rules="requiredRules"
            v-bind:label="$t('dashboard.firstname')"
            required
          ></v-text-field>
        </v-flex>

        <v-flex xs12 md6>
          <v-text-field
            v-model="lastname"
            :rules="requiredRules"
            v-bind:label="$t('dashboard.lastname')"
            required
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            disabled
            v-bind:label="$t('dashboard.email')"
            required
          ></v-text-field>
        </v-flex>

        <v-flex xs12 md6>
          <v-text-field
            v-model="password"
            :rules="passwordRules"
            v-bind:label="$t('dashboard.password')"
            :append-icon="show1 ? 'visibility' : 'visibility_off'"
            :type="show1 ? 'text' : 'password'"
            v-bind:hint="$t('dashboard.hint_password_length')"
            counter
            @click:append="show1 = !show1"
            required
          ></v-text-field>
        </v-flex>

        <v-flex xs12 md6>
          <v-text-field
            v-model="password_confirm"
            :rules="passwordConfirmRules"
            :append-icon="show1 ? 'visibility' : 'visibility_off'"
            :type="show1 ? 'text' : 'password'"
            v-bind:label="$t('dashboard.confirm_password')"
            @click:append="show1 = !show1"
            required
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-text-field
            v-model="address"
            v-bind:label="$t('dashboard.address')"
            :rules="requiredRules"
            required
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-text-field
            v-model="city"
            v-bind:label="$t('dashboard.city')"
            :rules="requiredRules"
            required
          ></v-text-field>
        </v-flex>

          <v-flex xs12>
          <v-text-field
            v-model="country"
            v-bind:label="$t('dashboard.country')"
            :rules="requiredRules"
            required
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-text-field
            v-model="vat_number"
            v-bind:label="$t('dashboard.vat_number')"
          ></v-text-field>
        </v-flex>

        <v-btn
            :disabled="!valid"
            class="btn btn--gold"
            @click="sendForm">
          <span class="btn__label">
            {{$t('dashboard.send_btn')}}
          </span>
        </v-btn>

      </v-layout>

      <div v-for="error in errors">
        <p>{{ error }}</p>
      </div>

    </v-container>
  </v-form>
</template>

<script>

const validateParams = {
  email_cr: "m",
  email: "email",
  token: "t"
}

export default {
  data () {
    return {
      errors: [],
      valid: true,
      firstname: '',
      lastname: '',
      address: '',
      city: '',
      country: '',
      vat_number: '',
      password: '',
      password_confirm: '',

      email: '',
      email_encrypt: '',
      token: null,

      requiredRules : [
        v => !!v || this.$i18n.t('dashboard.required_field'),
      ],
      emailRules: [
        v => !!v || this.$i18n.t('dashboard.required_email'),
        v => (/^[^@\s]+@[^@\s]+$/).test(v) || this.$i18n.t('dashboard.invalid_email')
      ],
      show1: false,
      passwordRules: [
        v => !!v || this.$i18n.t('dashboard.password_too_short'),
        v => v.length >= 8 || this.$i18n.t('dashboard.password_too_short'),
        v => new RegExp("^(?=.*[0-9])(?=.*[\_!@#\$€£§%\^&\*\-])").test(v) || this.$i18n.t('dashboard.password_requires_digit_and_symbol'),
      ],
      passwordConfirmRules: [
        v => !!v || this.$i18n.t('dashboard.confirm_password'),
        v => v === this.password || this.$i18n.t('dashboard.passwords_dont_match')
      ]
    }
  },
  methods: {
    sendForm: function () {
      this.errors = [];

      const data = {
        first_name: this.firstname,
        last_name: this.lastname,
        password: this.password,
        password_confirmation: this.password_confirm,
        address: this.address,
        city: this.city,
        country: this.country,
        vat_number: this.vat_number,
        email_encrypt: this.email_encrypt,
        email: this.email,
        token: this.token
      };

      this.$http.post(API_URL + "/signup", data, {responseType: "json"})
        .then((response) => {
          this.errors = ['Merci'];
          localStorage.setItem('user-token', response.body.auth_token.auth_token);
          this.$router.push('/subscribe');
        }, (response) => {
          this.errors = [response.body.message[this.$i18n.locale]];
        })
        .catch(error => {
          this.errors = [this.$i18n.t('dashboard.generic_error')];
        })
    }
  },
  mounted() {

    if (!this.$route.query[validateParams.email] || !this.$route.query[validateParams.token]  || !this.$route.query[validateParams.email_cr] ) {
      return this.$router.push('/')
    } else {
      this.token = this.$route.query[validateParams.token]
      this.email = this.$route.query[validateParams.email]
      this.email_encrypt = this.$route.query[validateParams.email_cr]
    }

  }
}
</script>
